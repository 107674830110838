import { Box, Divider, Flex, Text } from "@chakra-ui/react";
import React from "react";
import styles from "./Step.module.css";

interface IStepProps {
  stepNumber: number;
  title: string;
  description: string;
}

const Step = ({ stepNumber, title, description }: IStepProps) => {
  return (
    <Flex flexDirection="column" color="white">
      <Box className={styles.stepNumber}>{stepNumber}</Box>
      <Box>
        <Text textStyle="h3" as="h3" className={styles.step__title}>
          {title}
        </Text>
      </Box>
      <Divider />
      <Text textStyle="p" as="p" className={styles.step__description}>
        {description}
      </Text>
    </Flex>
  );
};

export default Step;
