import React, { useContext } from "react";
import { createClient } from "@/prismicio";
import {
  Box,
  ChakraProvider,
  Container,
  Stack,
  Heading,
  Text,
  Image,
  Flex,
  Grid,
} from "@chakra-ui/react";

import styles from "./StepsGrid.module.css";
import * as prismic from "@prismicio/client";
import { Content } from "@prismicio/client";
import { SliceComponentProps } from "@prismicio/react";
import Step from "@/components/Step/Step";
import { PrismicNextLink } from "@prismicio/next";
import { ModalContext } from "@/contexts/ModalContext";
import { AppConfig } from "@/utils/AppConfig";
import { Link as ScrollLink, animateScroll as scroll } from "react-scroll";
import FadeInWhenVisible from "@/components/FadeInWhenVisible/FadeInWhenVisible";

/**
 * Props for `StepsGrid`.
 */
export type StepsGridProps = SliceComponentProps<Content.StepsGridSlice>;

/**
 * Component for "StepsGrid" Slices.
 */
const StepsGrid = ({ slice, navigation, page }: any): JSX.Element => {
  const { openDownloadModal } = useContext(ModalContext);

  const handleOpenDownloadModal = () => {
    openDownloadModal();
  };

  const backgroundImage = slice.primary.background_image?.url;
  const fallbackImage = "/fallback-image-1.jpeg";
  const padding = prismic.asText(slice.primary.custom_padding);

  const divStyle = {
    backgroundImage: `linear-gradient(rgba(0, 0, 0, 0.8), rgba(0, 0, 0, 0.8)), url(${
      backgroundImage ? backgroundImage : fallbackImage
    })`,
    // padding: `${padding ? padding : "75px 0"}`,
  };
  return (
    <>
      <div className={styles.wrapper}>
        <div
          className={[styles.parallaxItem, "parallaxImageHelper"].join(" ")}
          style={divStyle}
        >
          <FadeInWhenVisible>
            <div className="container">
              <Flex textAlign={"center"} justifyContent={"center"}>
                <Image
                  opacity={0.3}
                  width="100px"
                  marginBottom="40px"
                  className={styles.logo}
                  src="/eca-logo.png"
                  alt={`${AppConfig.site_name} Logo`}
                />
              </Flex>
              {slice.primary.title && (
                <Heading
                  textStyle="h2"
                  as="h2"
                  letterSpacing="1px"
                  color="brand.white"
                  textTransform="uppercase"
                >
                  {prismic.asText(slice.primary.title)}
                </Heading>
              )}
              {slice.items.length > 0 && (
                <Grid className={styles.stepsGrid__grid}>
                  {slice.items.map((item, index) => (
                    <Step
                      key={index}
                      stepNumber={index + 1}
                      title={prismic.asText(item.title)}
                      description={prismic.asText(item.subtitle)}
                    />
                  ))}
                </Grid>
              )}
              {slice.primary.subtitle && (
                <Text textStyle="p" color="white" maxWidth="800px">
                  {prismic.asText(slice.primary.subtitle)}
                </Text>
              )}
              {slice.variation == "stepGridWithCtAs" && (
                <Box className={styles.stepsGrid__buttons}>
                  {/* <Link className="button" href={prismic.asLink(slice.primary.cta_link)}>
                  {prismic.asText(slice.primary.cta_text)}
                </Link> */}
                  {slice.primary.show_cta_1 && slice.primary.cta_1_text && (
                    <PrismicNextLink
                      className={[
                        "button button--large",
                        styles.stepsGrid__button,
                      ].join(" ")}
                      field={slice.primary.cta_1_link}
                    >
                      {prismic.asText(slice.primary.cta_1_text)}
                    </PrismicNextLink>
                  )}

                  {slice.primary.show_cta_2 && slice.primary.cta_2_text && (
                    <PrismicNextLink
                      className={[
                        "button button--large",
                        styles.stepsGrid__button,
                      ].join(" ")}
                      field={slice.primary.cta_2_link}
                    >
                      {prismic.asText(slice.primary.cta_2_text)}
                    </PrismicNextLink>
                  )}

                  {slice.primary.show_download_modal_cta && (
                    <Box>
                      <button
                        className={[
                          "button button--large",
                          styles.stepsGrid__button,
                        ].join(" ")}
                        onClick={handleOpenDownloadModal}
                      >
                        Download Course Prospectus
                      </button>
                    </Box>
                  )}

                  {slice.primary.show_modal_cta && (
                    <Box>
                      <ScrollLink
                        className={[
                          "button button--large",
                          styles.stepsGrid__button,
                        ].join(" ")}
                        to="calendlyBooking"
                        smooth
                        duration={500}
                      >
                        Arrange a Call
                      </ScrollLink>
                    </Box>
                  )}
                </Box>
              )}
            </div>
          </FadeInWhenVisible>
        </div>
      </div>
    </>
  );
};

export default StepsGrid;
